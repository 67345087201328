import {
  Button,
  Checkbox,
  Input,
  Modal2,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@hummingbird/shared';
import { useForm } from 'react-hook-form';

import { Form } from './CreateFundModal.styled';

import OrderTooltip from 'pages/PortalsAdmin/components/OrderTooltip/OrderTooltip';
import { NAME_MAX_LENGTH } from 'pages/PortalsAdmin/constants';

export interface FormData {
  name: string;
  order: number;
  visible: boolean;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: FormData) => void;
}

const CreateFundModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const { formState, register, handleSubmit } = useForm<FormData>();

  return (
    <Modal2 isOpen={isOpen} variant="form" onClose={onClose}>
      <ModalHeader title="Add Fund" />
      <ModalContent>
        <Form id="create-fund-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('name', {
              required: { value: true, message: 'This field is required' },
              maxLength: {
                value: NAME_MAX_LENGTH,
                message: 'Name should be less than 50 characters long',
              },
            })}
            isErrorInitiallyHidden
            error={formState.errors.name?.message}
            label="Fund name"
            placeholder="Enter Fund name"
          />
          <Input
            {...register('order')}
            isErrorInitiallyHidden
            label="Order"
            max={999}
            min={1}
            placeholder="(number)"
            tooltipLabel={<OrderTooltip />}
            type="number"
          />
          <Checkbox {...register('visible')} label="Is Visible" />
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button variant="outlined-primary" onClick={onClose}>
          Cancel
        </Button>
        <Button aria-label="Add Fund" form="create-fund-form" type="submit">
          Add Fund
        </Button>
      </ModalFooter>
    </Modal2>
  );
};

export default CreateFundModal;
