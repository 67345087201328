import {
  Button,
  Dropdown,
  Input,
  Modal2,
  ModalContent,
  ModalFooter,
  ModalHeader,
  type DropdownOption,
} from '@hummingbird/shared';
import { Controller, useForm } from 'react-hook-form';

import { Form } from './GrantSiteAccessModal.styled';

const commonRoles = ['KISKI-PUBLISHER', 'KISKI-VIEWER', 'PORTAL-VIEWER'];

export interface AccessFormData {
  email: string;
  role: string;
}

interface Props {
  isOpen: boolean;
  title: string;
  isSite?: boolean;
  isEditMode: boolean;
  role: string;
  onClose: () => void;
  onSubmit: (formData: AccessFormData) => void;
}

const GrantSiteAccessModal = ({
  isOpen,
  title,
  isSite = true,
  isEditMode,
  role,
  onClose,
  onSubmit,
}: Props) => {
  const { register, handleSubmit, control } = useForm<AccessFormData>({
    defaultValues: {
      email: '',
      role,
    },
  });

  const roles = isSite ? commonRoles : [...commonRoles, 'FUND-VIEWER'];

  const dropdownOptions: DropdownOption[] = roles.map(r => ({
    label: r,
    value: r,
  }));

  return (
    <Modal2 isOpen={isOpen} variant="form" onClose={onClose}>
      <ModalHeader title={title} />
      <ModalContent>
        <Form id="grant-site-access-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('email')}
            isErrorInitiallyHidden
            label="Email"
            placeholder="Enter email"
          />
          {isEditMode && (
            <Controller
              control={control}
              name="role"
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  inputProps={{
                    label: 'Role',
                    placeholder: 'Enter role',
                    isErrorInitiallyHidden: true,
                  }}
                  options={dropdownOptions}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          )}
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button variant="outlined-primary" onClick={onClose}>
          Cancel
        </Button>
        <Button form="grant-site-access-form" type="submit">
          Apply
        </Button>
      </ModalFooter>
    </Modal2>
  );
};

export default GrantSiteAccessModal;
