import { EditIcon, Input, RightArrowIcon, Spinner } from '@hummingbird/shared';
import { useCallback, useState } from 'react';

import { TitleInputContainer } from './FolderTitle.styled';

import { queryClient } from 'config';
import { NAME_MAX_LENGTH } from 'pages/PortalsAdmin/constants';
import useUpdateFolder from 'services/api/useUpdateFolder';
import { QUERY_KEYS } from 'services/constants';
import { addNotification } from 'services/notifications';
import { isAxiosError } from 'utils/typeGuards';

interface Props {
  folder: Folder;
  fund: Fund;
  site: Site;
}

const FolderTitle = ({ fund, site, folder }: Props) => {
  const [isEditingMode, setEditingMode] = useState(false);
  const [folderName, setFolderName] = useState(folder.name);

  const closeEditingMode = () => {
    setEditingMode(false);
  };

  const { mutate: updateFolder, isLoading: isFolderUpdating } = useUpdateFolder(
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries([QUERY_KEYS.FOLDERS, folder.id]);
        }, 1000);
      },
      onError: error => {
        if (!error || !isAxiosError(error)) {
          return;
        }

        const responseError = error as {
          response: { data: { message: string } };
        };

        addNotification({
          message: responseError.response.data.message,
          title: 'Error',
          type: 'danger',
        });
      },
    },
  );

  const updateTitleIfChanged = useCallback(() => {
    const didNameChanged = folderName !== folder.name;

    if (!didNameChanged) {
      closeEditingMode();

      return;
    }

    if (folderName.length > NAME_MAX_LENGTH) {
      addNotification({
        message: 'Name should be less than 50 characters long',
        title: 'Error',
        type: 'danger',
      });
    } else {
      const payload = {
        id: folder.id,
        siteId: site.id,
        fundId: fund.id,
        payload: {
          name: folderName,
        },
      };

      updateFolder(payload);
    }

    closeEditingMode();
  }, [folder.id, folder.name, folderName, fund.id, site.id, updateFolder]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        closeEditingMode();
      } else if (event.key === 'Enter') {
        updateTitleIfChanged();
      }
    },
    [updateTitleIfChanged],
  );

  if (isFolderUpdating) {
    return <Spinner aria-label="Spinner" />;
  }

  if (isEditingMode) {
    return (
      <TitleInputContainer>
        <Input
          autoFocus
          isErrorInitiallyHidden
          isLabelHidden
          elementSize="small"
          label="title"
          value={folderName}
          onBlur={updateTitleIfChanged}
          onChange={e => setFolderName(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
      </TitleInputContainer>
    );
  }

  return (
    <>
      {site.portal_name} <RightArrowIcon height={24} /> {fund.name}{' '}
      <RightArrowIcon height={24} /> {folder.name}
      <EditIcon
        aria-label="Edit title"
        height={24}
        onClick={() => {
          setFolderName(folder.name);
          setEditingMode(true);
        }}
      />
    </>
  );
};

export default FolderTitle;
