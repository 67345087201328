import { IconButton, LeftArrowIcon } from '@hummingbird/shared';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <IconButton
      icon={LeftArrowIcon}
      label="back"
      size="small"
      variant="outlined"
      onClick={handleOnClick}
    />
  );
};

export default BackButton;
