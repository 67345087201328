import { EditIcon, Input, Spinner } from '@hummingbird/shared';
import { useCallback, useState } from 'react';

import { TitleInputContainer } from './SiteTitle.styled';

import { queryClient } from 'config';
import { NAME_MAX_LENGTH } from 'pages/PortalsAdmin/constants';
import useUpdateSite from 'services/api/useUpdateSite';
import { QUERY_KEYS } from 'services/constants';
import { addNotification } from 'services/notifications';

interface Props {
  site: Site;
}

const SiteTitle = ({ site }: Props) => {
  const [isEditingMode, setEditingMode] = useState(false);
  const [siteName, setSiteName] = useState(site.portal_name);

  const closeEditingMode = () => {
    setEditingMode(false);
  };

  const { mutate: updateSite, isLoading: isSiteUpdating } = useUpdateSite({
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.SITE, site.id]);
      closeEditingMode();
    },
  });

  const updateTitleIfChanged = useCallback(() => {
    const didNameChanged = siteName !== site.portal_name;

    if (!didNameChanged) {
      closeEditingMode();

      return;
    }

    if (siteName.length > NAME_MAX_LENGTH) {
      addNotification({
        message: 'Name should be less than 50 characters long',
        title: 'Error',
        type: 'danger',
      });
    } else {
      updateSite({
        id: site.id,
        payload: {
          name: siteName,
        },
      });
    }

    closeEditingMode();
  }, [site, siteName, updateSite]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        closeEditingMode();
      } else if (event.key === 'Enter') {
        updateTitleIfChanged();
      }
    },
    [updateTitleIfChanged],
  );

  if (isSiteUpdating) {
    return <Spinner aria-label="Spinner" />;
  }

  if (isEditingMode) {
    return (
      <TitleInputContainer>
        <Input
          autoFocus
          isErrorInitiallyHidden
          isLabelHidden
          elementSize="small"
          label="title"
          value={siteName}
          onBlur={updateTitleIfChanged}
          onChange={e => setSiteName(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
      </TitleInputContainer>
    );
  }

  return (
    <>
      {site.portal_name}
      <EditIcon
        aria-label="Edit title"
        height={24}
        onClick={() => {
          setSiteName(site.portal_name);
          setEditingMode(true);
        }}
      />
    </>
  );
};

export default SiteTitle;
