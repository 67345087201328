import '@fontsource/rubik';
import {
  createTheme,
  SharedComponentsProvider,
  ThemeProvider,
} from '@hummingbird/shared';
import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { RouterProvider } from 'react-router-dom';

import { queryClient } from 'config';
import { AuthProvider } from 'context/authContext';
import router from 'routes/router';
import { GlobalStyle } from 'theme';

import 'react-notifications-component/dist/theme.css';

const theme = createTheme({ preset: 'kiski' });

const translations = {
  general: {
    draft: 'Draft',
    somethingWentWrong: 'Something went wrong',
  },
};

const delayDuration = 200;

function App() {
  return (
    <Suspense fallback={null}>
      <ThemeProvider theme={theme}>
        <SharedComponentsProvider translations={translations}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <TooltipProvider delayDuration={delayDuration}>
                <ReactNotifications />
                <GlobalStyle />
                <RouterProvider router={router} />
              </TooltipProvider>
            </AuthProvider>
          </QueryClientProvider>
        </SharedComponentsProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
