import { RefreshIcon, Spinner } from '@hummingbird/shared';
import { useEffect, useState } from 'react';

import { TableActionButton } from './WorkbooksCard.styled';

import Tooltip from 'components/Tooltip/Tooltip';
import useRefreshWorkbook from 'services/api/useRefreshWorkbook';

const DISABLE_DURATION_MS = 60_000;

type Props = {
  workbookId: string;
  siteId: string;
};

/**
 * A button that triggers a workbook refresh for the specified site.
 *
 * Clicking the button sends a refresh signal to the backend. To prevent excessive
 * requests, the button is disabled for a fixed duration after being clicked.
 */
const RefreshWorkbookButton: React.FC<Props> = ({ siteId, workbookId }) => {
  const { mutate: refreshWorkbook, isLoading } = useRefreshWorkbook();

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (!isDisabled) return undefined;

    const timeoutId = setTimeout(() => {
      setIsDisabled(false);
    }, DISABLE_DURATION_MS);

    return () => clearTimeout(timeoutId);
  }, [isDisabled]);

  return (
    <Tooltip content="Refresh Extract" side="right">
      <TableActionButton
        disabled={isLoading || isDisabled}
        icon={isLoading ? Spinner : RefreshIcon}
        label="refresh"
        variant="simple"
        onClick={() => {
          setIsDisabled(true);
          refreshWorkbook({ workbookId, siteId });
        }}
      />
    </Tooltip>
  );
};

export default RefreshWorkbookButton;
