import {
  EditIcon,
  IconButton,
  OptionsIcon,
  colors,
  useOnClickOutside,
} from '@hummingbird/shared';
import { useEffect, useRef, useState } from 'react';
import { FocusOn } from 'react-focus-on';
import { useParams } from 'react-router-dom';

import ModifyAccess from './ModifyAccess/ModifyAccess';
import { Close, Container, MenuList, MenuListItem } from './UserMenu.styled';

import { AccessFormData } from 'pages/PortalsAdmin/Sites/components/Site/components/SiteUsersCard/GrantSiteAccessForm/GrantSiteAccessModal';
import useGrantSiteAccess from 'services/api/userProvisioning/useGrantSiteAccess';
import { addNotification } from 'services/notifications';

interface Props {
  user: User;
  onRevoke: (email: string) => void;
}

const UserMenu = ({ user, onRevoke }: Props) => {
  const { siteId = '' } = useParams();

  const { mutate: modifyAccess } = useGrantSiteAccess();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isModifyAccessModalOpen, setModifyAccessModalOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useOnClickOutside(ref, closeMenu);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isMenuOpen) {
        closeMenu();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isMenuOpen]);

  const closeModifyAccessMenu = () => {
    setModifyAccessModalOpen(false);
  };

  const handleModifyAccessFormOnSubmit = (data: AccessFormData) => {
    setModifyAccessModalOpen(false);

    if (!siteId) {
      return;
    }

    const payload = {
      siteId: siteId,
      email: data.email,
      role: data.role,
    };

    modifyAccess(payload, {
      onError: () => {
        addNotification({
          message: '',
          title: 'Error',
          type: 'danger',
        });
      },
    });
  };

  const handleOnRevokeAccessClick = () => {
    closeMenu();
    onRevoke(user.email);
  };

  return (
    <>
      <IconButton
        icon={OptionsIcon}
        label="Actions"
        variant="simple"
        onClick={() => setMenuOpen(prevOpen => !prevOpen)}
      />
      <Container ref={ref}>
        {isMenuOpen && (
          <FocusOn
            allowPinchZoom={true}
            autoFocus={false}
            onClickOutside={closeMenu}
            onEscapeKey={closeMenu}>
            <MenuList $isBottom={user.site_role === 'Kiski Publisher'}>
              <MenuListItem
                aria-label="Modify Access"
                onClick={() => setModifyAccessModalOpen(true)}>
                <EditIcon />
                Modify Access
              </MenuListItem>
              <hr />
              <MenuListItem
                $color={colors.error}
                aria-label="Revoke Access"
                onClick={handleOnRevokeAccessClick}>
                <Close />
                Revoke Access
              </MenuListItem>
            </MenuList>
          </FocusOn>
        )}
      </Container>
      <ModifyAccess
        isOpen={isModifyAccessModalOpen}
        user={user}
        onClose={closeModifyAccessMenu}
        onSubmit={handleModifyAccessFormOnSubmit}
      />
    </>
  );
};

export default UserMenu;
