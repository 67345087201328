import {
  Button,
  DeleteIcon,
  IconButton,
  Modal2,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@hummingbird/shared';
import { useState } from 'react';

import useDeleteSite from 'services/api/useDeleteSite';

interface Props {
  site: Site;
}

const DeleteSiteButton = ({ site }: Props) => {
  const { mutate: deleteSite } = useDeleteSite();

  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOnConfirm = () => {
    deleteSite({ id: site.id });
    closeModal();
  };

  return (
    <>
      <IconButton
        icon={DeleteIcon}
        label="Delete"
        variant="simple"
        onClick={() => setModalOpen(true)}
      />
      <Modal2 isOpen={isModalOpen} variant="info" onClose={closeModal}>
        <ModalHeader
          title={`Are you sure you want to delete "${site.portal_name}" site?`}
        />
        <ModalContent>Site and all of its content will be deleted</ModalContent>
        <ModalFooter>
          <Button variant="outlined-primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={handleOnConfirm}>Yes, I am sure</Button>
        </ModalFooter>
      </Modal2>
    </>
  );
};

export default DeleteSiteButton;
