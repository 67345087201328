import {
  Button,
  EditIcon,
  Input,
  Modal2,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useBoolean,
} from '@hummingbird/shared';
import { useCallback, useState, type KeyboardEvent } from 'react';

import {
  Container,
  EditableInputContainer,
  ReadOnlyInputContainer,
} from './EditableOrderCell.styled';

import { addNotification } from 'services/notifications';

interface Props {
  value: number;
  name: 'Site Fund' | 'Folder' | 'Workbook';
  onUpdate: (value: number) => void;
}

const EditableOrderCell = ({ value, name, onUpdate }: Props) => {
  const [updatedValue, setUpdatedValue] = useState(value);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const {
    value: isEditing,
    setTrue: openEditingMode,
    setFalse: closeEditingMode,
  } = useBoolean(false);

  const resetToInitialValue = useCallback(() => {
    closeEditingMode();
    setUpdatedValue(value);
  }, [closeEditingMode, value]);

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
    resetToInitialValue();
  };

  const handeConfirmationModelOnConfirm = () => {
    setConfirmationModalOpen(false);
    closeEditingMode();
    onUpdate(updatedValue);
  };

  const handleOnBlur = useCallback(() => {
    const didValueChanged = updatedValue !== value;

    if (!didValueChanged) {
      resetToInitialValue();

      return;
    }

    if (updatedValue < 1 || updatedValue > 999) {
      addNotification({
        message: 'Order should be number from 1 to 999',
        title: 'Error',
        type: 'danger',
      });

      resetToInitialValue();
    } else {
      setConfirmationModalOpen(true);
    }
  }, [resetToInitialValue, updatedValue, value]);

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const didValueChanged = updatedValue !== value;

      const isEscapeClicked = event.key === 'Escape';
      const isEnterClicked = event.key === 'Enter';

      const shouldReset =
        isEscapeClicked || (isEnterClicked && !didValueChanged);

      if (shouldReset) {
        resetToInitialValue();

        return;
      }

      if (isEnterClicked && didValueChanged) {
        if (updatedValue < 1 || updatedValue > 999) {
          addNotification({
            message: 'Order should be number from 1 to 999',
            title: 'Error',
            type: 'danger',
          });

          resetToInitialValue();

          return;
        }
        setConfirmationModalOpen(true);
      }
    },
    [resetToInitialValue, updatedValue, value],
  );

  const handleIconOnClick = () => {
    setUpdatedValue(updatedValue);
    openEditingMode();
  };

  return (
    <>
      <Container>
        {isEditing ? (
          <EditableInputContainer>
            <Input
              autoFocus
              isErrorInitiallyHidden
              isLabelHidden
              elementSize="small"
              label="order"
              type="number"
              value={updatedValue}
              onBlur={handleOnBlur}
              onChange={e => setUpdatedValue(+e.target.value)}
              onKeyDown={handleOnKeyDown}
            />
          </EditableInputContainer>
        ) : (
          <ReadOnlyInputContainer>
            {value}
            <EditIcon height={18} onClick={handleIconOnClick} />
          </ReadOnlyInputContainer>
        )}
      </Container>
      <Modal2
        isOpen={!!isConfirmationModalOpen}
        variant="info"
        onClose={closeConfirmationModal}>
        <ModalHeader title={`Are you sure you want to change ${name} order?`} />
        <ModalContent>
          Changing the {name} order will affect how the client sees it.
        </ModalContent>
        <ModalFooter>
          <Button variant="outlined-primary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          <Button onClick={handeConfirmationModelOnConfirm}>
            Yes, I am sure
          </Button>
        </ModalFooter>
      </Modal2>
    </>
  );
};

export default EditableOrderCell;
