import {
  FinancialStatementIcon,
  PlusIcon,
  Table,
  type SortOrder,
  type TableData,
} from '@hummingbird/shared';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import useFundsTable from '../../useFundsTable/useFundsTable';
import CreateFundModal, {
  type FormData,
} from '../CreateFundModal/CreateFundModal';

import {
  AddFundButton,
  Card,
  CardHeader,
  TitleContainer,
} from './FundsCard.styled';

import CenteredSpinner from 'components/CenteredSpinner/CenteredSpinner';
import useCreateFund from 'services/api/useCreateFund';
import useFunds from 'services/api/useFunds';
import useSite from 'services/api/useSite';
import { QUERY_KEYS } from 'services/constants';

const FundsCard = () => {
  const { siteId = '' } = useParams();
  const queryClient = useQueryClient();

  const { data: site, isLoading: isSiteLoading } = useSite(siteId);
  const { data: funds, isLoading: areFundsLoading } = useFunds(siteId);

  const { mutate: createFund, isLoading: isFundCreating } = useCreateFund();

  const [isCreateFundModalOpen, setCreateFundModalOpen] = useState(false);

  const { tableColumns, tableData, sortKey, sortOrder, setSort } =
    useFundsTable(funds || [], site);

  const closeCreateFundModal = () => {
    setCreateFundModalOpen(false);
  };

  const handleCreateFundFormOnSubmit = (formData: FormData) => {
    const { order, visible, name } = formData;

    if (!site) {
      return;
    }

    const payload: CreateFundPayload = {
      siteId: site.id,
      payload: {
        name,
        options: {
          hidden: !visible,
          order: order || undefined,
        },
      },
    };

    closeCreateFundModal();
    createFund(payload, {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries([QUERY_KEYS.FUNDS, site.id]);
        }, 2000);
      },
    });
  };

  return (
    <>
      <Card aria-label="Funds">
        <CardHeader>
          <TitleContainer>
            <FinancialStatementIcon height={24} />
            Funds
          </TitleContainer>
          <AddFundButton
            aria-label="Add Fund"
            icon={PlusIcon}
            iconPosition="left"
            onClick={() => setCreateFundModalOpen(true)}>
            Add Fund
          </AddFundButton>
        </CardHeader>

        {areFundsLoading || isSiteLoading || isFundCreating ? (
          <CenteredSpinner />
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            sortColumn={sortKey}
            sortDirection={sortOrder}
            onSort={setSort as (key: keyof TableData, order: SortOrder) => void}
          />
        )}
      </Card>
      <CreateFundModal
        isOpen={isCreateFundModalOpen}
        onClose={closeCreateFundModal}
        onSubmit={handleCreateFundFormOnSubmit}
      />
    </>
  );
};

export default FundsCard;
